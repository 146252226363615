/**
* Generated automatically at built-time (2024-10-08T07:33:33.132Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-cantinetta-vino",templateKey: "sites/69-e393f6ee-a184-4cd1-97a8-c81983c6fbb9"};